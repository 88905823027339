/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import styled from "styled-components"

import Container from "@components/Container"
import Layout from "@components/Layouts"
import theme, { rem } from "@src/theme"
import SEO from "@components/seo"
import Link from "@components/Link"
import ImageBannerCard from "@components/ImageBannerCard"
import MarkdownBody from "@modules/MarkdownBody"
import Section from "@components/Section"
export default function Template ({
  data, // this prop will be injected by the GraphQL query below.
  ...props
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { title, image, link, pdf, ogTitle, ogdescription, ogimage } = frontmatter

  return (
    <Layout>
      <SEO
        title={ogTitle || title}
        pathname={props.location.pathname}
        image={ogimage || frontmatter.image}
        description={ogdescription || frontmatter.cardPreview}
      />
      <Section
        sx={{
          bg: "pink",
          color: "darkPurple",
        }}
      >
        <Container
          sx={{
            maxWidth: rem(880),
          }}
        >
          <ImageBannerCard image={image} title={title}>
            <MarkdownBody html={html} />

            <FooterActions
              sx={{
                flexDirection: ["column", "row"],
                alignItems: ["normal", "center"],
              }}
            >
              <div
                sx={{
                  mb: [3, 0],
                }}
              >
                {pdf || link ? (
                  <Link external link={pdf || link}>
                    {pdf ? "Download" : "View resource"}
                  </Link>
                ) : null}
              </div>
            </FooterActions>
          </ImageBannerCard>
        </Container>
      </Section>
    </Layout>
  )
}

const FooterActions = styled.div`
  display: flex;

  justify-content: space-between;

  button.scroll-action {
    cursor: pointer;
    font-weight: 700;
    color: ${theme.colors.darkPurple};
    text-decoration: underline;
  }

  a.socials {
    cursor: pointer;
    margin-right: ${theme.space[4]};
    svg {
      transform: translateY(2px);
    }
    span {
      display: inline-block;
      margin-left: 5px;
      font-weight: 700;
      text-decoration: underline;
    }
  }
`
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image
        link
        pdf
        ogTitle
        ogdescription
        ogimage
      }
    }
  }
`
